import React from "react";
import AppBar from "./AppBar";
import IntroText from "./Body/IntroText";
import BodyCards from "./Body/BodyCards";
import ReferanslarAciklama from "./Body/ReferanslarAciklama";
import Logo from "../Assets/newlogo.png";

import { BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";
function HomePage() {
  return (
    <div>
      <AppBar></AppBar>

      <main className="bg-slate-800 min-h-screen ">
        <section className="pt-10 bg-gradient-to-l from-slate-100 pb-10 h-[50vh] relative">
          <IntroText></IntroText>
        </section>

        <section className="px-10 pt-10 ">
          <BodyCards></BodyCards>
        </section>
        <section className="px-10 pt-10">
          <ReferanslarAciklama></ReferanslarAciklama>
        </section>

        <footer className="rounded-lg shadow bg-gray-900 mt-20">
          <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
            <div className="sm:flex sm:items-center sm:justify-between">
              <span className="flex items-center mb-4 sm:mb-0">
                <img src={Logo} className="h-12 mr-3" alt="Flowbite Logo" />
        
              </span>
              <ul className="flex flex-wrap items-center mb-6 text-sm font-medium gap-5 sm:mb-0 text-gray-400">
                <li>
                  <a
                    href={
                      "https://www.linkedin.com/company/orsa-proje-danışmanlık/"
                    }
                  >
                    <BsLinkedin className="text-3xl"></BsLinkedin>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@orsa2009">
                    <BsYoutube className="text-3xl"></BsYoutube>
                  </a>
                </li>
                <li>
                  <a href={"https://www.instagram.com/orsaproje/"}>
                    <BsInstagram className="text-3xl"></BsInstagram>
                  </a>
                </li>
              </ul>
            </div>
            <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />
            <span className="block text-sm  sm:text-center text-gray-400">
              © 2023 <span className="hover:underline">Calybre3D™</span>. Her
              Hakkı Saklıdır.
            </span>
          </div>
        </footer>
      </main>
    </div>
  );
}

export default HomePage;
