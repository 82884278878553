import React, { useState } from "react";
import { AiOutlineHome } from "react-icons/ai";
import Logo from "../../Assets/newlogo.png";
import { useNavigate } from "react-router-dom";
import { FcAddImage } from "react-icons/fc";
import { getAuth } from "firebase/auth";
import { fireDB, firebaseApp, strogeDb } from "../../Config/FirebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LoadingScreen from "../LoadingScreen";
const auth = getAuth(firebaseApp);
function TaramaModelleme() {
  let [malzemeSecimi, setMalmzemeSecimi] = useState(0);
  let navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  let [resim1, setResim1] = useState(null);
  let [resim2, setResim2] = useState(null);
  let [resim3, setResim3] = useState(null);
  let [resim4, setResim4] = useState(null);

  let [eni, setEni] = useState("0");

  let [boyu, setBoyu] = useState("0");

  let [yukseklik, setYukselik] = useState("0");

  let [terminSuresi, setTerminSuresi] = useState("0");
  let [aciklama, setAciklama] = useState("");
  let [uploadLoading, setUploadLoading] = useState(null);

  const malzemeCesidi = [
    { id: 0, label: "Plastik" },
    { id: 1, label: "Döküm" },
    { id: 2, label: "Strafor" },
    { id: 3, label: "Diğer" },
  ];
  const basimHandle = (itemId) => {
    setMalmzemeSecimi(itemId);
  };

  const resimOneHandle = (event) => {
    const selectedFile = event.target.files[0];
    setResim1(selectedFile);
  };

  const resimTwoHandle = (event) => {
    const selectedFile = event.target.files[0];
    setResim2(selectedFile);
  };

  const resimThreeHandle = (event) => {
    const selectedFile = event.target.files[0];
    setResim3(selectedFile);
  };

  const resimForHandle = (event) => {
    const selectedFile = event.target.files[0];
    setResim4(selectedFile);
  };




  const taramaHizmetiEkle = async () => {
    try {
      if (user != null) {
        if (resim1 != null && resim2 != null && resim3 != null && resim4 != null) {
          setUploadLoading(true);
          let userId = user.uid;
          const yeniMesaj = doc(fireDB, "USERS", userId);
          const itemDocRef = collection(
            doc(collection(fireDB, "USERS"), userId),
            "taramaModelleme"
          );
          const userCreden = await getDoc(yeniMesaj);
          let userData = userCreden.data();
    
          // Resim 1
          let resim1FileName = resim1.name; // Dosya adı ve uzantısını koruyoruz
          const resim1Ref = ref(strogeDb, `${userId}/taramaModelleme/${uuidv4()}.${resim1FileName.split('.').pop()}`);
          await uploadBytes(resim1Ref, resim1);
          let resim1Link = await getDownloadURL(resim1Ref);
    
          // Resim 2
          let resim2FileName = resim2.name;
          const resim2Ref = ref(strogeDb, `${userId}/taramaModelleme/${uuidv4()}.${resim2FileName.split('.').pop()}`);
          await uploadBytes(resim2Ref, resim2);
          let resim2Link = await getDownloadURL(resim2Ref);
    
          // Resim 3
          let resim3FileName = resim3.name;
          const resim3Ref = ref(strogeDb, `${userId}/taramaModelleme/${uuidv4()}.${resim3FileName.split('.').pop()}`);
          await uploadBytes(resim3Ref, resim3);
          let resim3Link = await getDownloadURL(resim3Ref);
    
          // Resim 4
          let resim4FileName = resim4.name;
          const resim4Ref = ref(strogeDb, `${userId}/taramaModelleme/${uuidv4()}.${resim4FileName.split('.').pop()}`);
          await uploadBytes(resim4Ref, resim4);
          let resim4Link = await getDownloadURL(resim4Ref);
    
          let now = Date.now();
    
          // Firebase'e doküman ekleme
          await updateDoc(yeniMesaj, { mesaj: true });
          await addDoc(itemDocRef, {
            malzemeCesidi: malzemeCesidi[malzemeSecimi].label,
            boyutBilgisi: `${eni} x ${boyu} x ${yukseklik} mm`,
            dosyaLink: [resim1Link, resim2Link, resim3Link, resim4Link],
            aciklama: aciklama,
            teslimSuresi: terminSuresi,
            incelenmeDurumu: false,
            zaman: Date.now(),
          });
    
          // Mail eklerine resim dosyaları ekleme
          const mailfile = [
            { filename: resim1FileName, fileUrl: resim1Link },
            { filename: resim2FileName, fileUrl: resim2Link },
            { filename: resim3FileName, fileUrl: resim3Link },
            { filename: resim4FileName, fileUrl: resim4Link },
          ];
    
          // E-posta gönderimi
          const mailResponse = await axios.post("http://apitest.calybre3d.com/index.php", {
            "to": "mustafab@orsaproje.com",
            "headerdata": `${userData["isim"]} - Tarama Modelleme`,
            "bodymessage": `
    <!DOCTYPE html>
    <html lang="tr">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <style>
        body {
          font-family: Arial, sans-serif;
          background-color: #f4f4f4;
          margin: 0;
          padding: 0;
        }
        .container {
          max-width: 600px;
          margin: 20px auto;
          background-color: #ffffff;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        .header {
          text-align: center;
          padding-bottom: 20px;
          border-bottom: 1px solid #dddddd;
        }
        .header h1 {
          color: #0e2c66;
          font-size: 24px;
          margin: 0;
        }
        .content {
          padding: 20px 0;
        }
        .content h2 {
          font-size: 18px;
          color: #333333;
        }
        .content p {
          margin: 10px 0;
          font-size: 16px;
          color: #555555;
        }
        .footer {
          text-align: center;
          margin-top: 30px;
          padding-top: 20px;
          border-top: 1px solid #dddddd;
          font-size: 14px;
          color: #888888;
        }
      </style>
    </head>
    <body>
    
      <div class="container">
        <div class="header">
          <h1>Tarama Talep Detayları</h1>
        </div>
    
        <div class="content">
    
          <h2>Müşteri Bilgileri:</h2>
          <p><strong>İsim:</strong> ${userData["isim"]}</p>
          <p><strong>Soyisim:</strong> ${userData["soyisim"]}</p>
          <p><strong>Firma:</strong> ${userData["firma"]}</p>
          <p><strong>E-posta:</strong> ${userData["eposta"]}</p>
    
          <h2>Malzeme Çeşidi</h2>
          <p>${malzemeCesidi[malzemeSecimi].label}</p>
    
          <h2>Boyut Bilgisi</h2>
          <p>${eni} x ${boyu} x ${yukseklik} mm</p>
    
          <h2>Açıklama</h2>
          <p>${aciklama}</p>
    
          <h2>Teslim Süresi</h2>
          <p>${terminSuresi} gün</p>
    
          <h2>Sipariş Veriliş Zamanı</h2>
          <p>${ Date(now).toLocaleString()}</p>
        </div>
    
        <div class="footer">
          <p>Bu e-posta otomatik olarak oluşturulmuştur. Lütfen cevaplamayınız.</p>
        </div>
      </div>
    
    </body>
    </html>
            `,
            "mailfile": mailfile // Resimler eklenecek
          });
    
          NotificationManager.success(
            "En kısa sürede dönüş yapılacaktır.",
            "Ürün Eklendi",
            2000
          );
    
          // Sayfa temizleme
          setTimeout(() => {
            setUploadLoading(false);
            navigate("/");
          }, 3000);
          setResim1(null);
          setResim2(null);
          setResim3(null);
          setResim4(null);
          setEni(0);
          setBoyu(0);
          setYukselik(0);
        } else {
          NotificationManager.info("Lütfen 4 adet resim ekleyiniz.", "Eksik resim", 2000);
        }
      }
    } catch (e) {
      NotificationManager.error("Lütfen Tekrar Deneyiniz", "Hata Oluştu", 2000);
      setUploadLoading(false);
    }
    
  };
  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-900">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-2/3 lg:w-1/2 xl:w-1/3">
        <div className="flex items-center justify-start mb-6">
        <img src={Logo} alt="Logo" className="w-28 h-10 mr-2" />
       
        </div>
        <h2 className="text-2xl font-semibold mb-4">Tarama Modelleme</h2>
        <p className="font-thin">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.{" "}
        </p>
        <div className="mb-4 mt-4">
          <div className="mb-4 mt-4">
            <label
              htmlFor="name"
              className="block font-medium text-gray-700 mb-2"
            >
              Basım Teknolojisi:
            </label>
            <div className="flex flex-wrap mt-4">
              {malzemeCesidi.map((item) => (
                <div key={item.id} className="w-1/2">
                  <div className="flex items-center text-gray-500">
                    <input
                      type="radio"
                      id={`item-${item.id}`}
                      checked={malzemeSecimi === item.id}
                      onChange={() => basimHandle(item.id)}
                      className="mr-2"
                    />
                    <label htmlFor={`item-${item.id}`}>{item.label}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <label htmlFor="email" className="block font-medium text-gray-700 mb-2">
          Boyut Bilgisi:{" "}
          <span className="text-opacity-50 text-sm text-black">(mm)</span>
        </label>
        <div className="flex gap-12">
          <div className="mb-4">
            <label
              htmlFor="eni"
              className="block font-sm text-sm text-gray-700 mb-2"
            >
              Eni:{" "}
              <span className="text-opacity-50 text-sm text-black">(x)</span>
            </label>
            <input
              onChange={(e) => setEni(e.target.value)}
              value={eni}
              type="text"
              id="eni"
              className="px-4 py-2 w-24 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="boyu"
              className="block font-sm text-sm text-gray-700 mb-2"
            >
              Boyu:{" "}
              <span className="text-opacity-50 text-sm text-black">(y)</span>
            </label>
            <input
              onChange={(e) => setBoyu(e.target.value)}
              value={boyu}
              type="text"
              id="boyu"
              className="px-4 py-2 w-24 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="yukseklik"
              className="block font-sm text-sm text-gray-700 mb-2"
            >
              Yüksekliği:{" "}
              <span className="text-opacity-50 text-sm text-black">(z)</span>
            </label>
            <input
              onChange={(e) => setYukselik(e.target.value)}
              value={yukseklik}
              type="text"
              id="yukseklik"
              className="px-4 py-2 w-24 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
        </div>
        <div className="mb-4 ">
          <label
            htmlFor="multiText"
            className="block font-medium text-gray-700 mb-2"
          >
            Açıklama:
          </label>
          <textarea
            onChange={(e) => setAciklama(e.target.value)}
            value={aciklama}
            id="multiText"
            className="w-full p-2 border rounded"
            rows="4"
            placeholder="İhtiyaçlarınızı Belirtiniz..."
          ></textarea>
        </div>
        <div className="mb-4">
          <label
            htmlFor="terminSure"
            className="block font-sm text-sm text-gray-700 mb-2"
          >
            Teslim Süresi:{" "}
            <span className="text-opacity-50 text-sm text-black">(gün)</span>
          </label>
          <input
            onChange={(e) => setTerminSuresi(e.target.value)}
            value={terminSuresi}
            type="text"
            id="terminSure"
            className="px-4 py-2 w-24 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
          />
        </div>
        <div className="mb-4 ">
          <label
            htmlFor="email"
            className="block font-medium text-gray-700 mb-2"
          >
            Ürün Resimi:
          </label>
          <div className="flex justify-evenly mt-10">
            <div
              className={`${
                resim1 ? "w-32 h-32" : "bg-gray-300 p-5"
              } rounded-md`}
            >
              {resim1 ? (
                <img
                  src={URL.createObjectURL(resim1)}
                  alt="Selected"
                  className="flex h-32 w-32"
                />
              ) : (
                <label htmlFor="1" className="cursor-pointer">
                  <FcAddImage className="h-16 w-16" />
                </label>
              )}
              <input
                type="file"
                id="1"
                accept="image/*"
                className="hidden"
                onChange={resimOneHandle}
              />
            </div>

            <div
              className={`${
                resim2 ? "w-32 h-32" : "bg-gray-300 p-5"
              } rounded-md`}
            >
              {resim2 ? (
                <img
                  src={URL.createObjectURL(resim2)}
                  alt="Selected"
                  className="flex h-32 w-32"
                />
              ) : (
                <label htmlFor="2" className="cursor-pointer">
                  <FcAddImage className="h-16 w-16" />
                </label>
              )}
              <input
                type="file"
                id="2"
                accept="image/*"
                className="hidden"
                onChange={resimTwoHandle}
              />
            </div>

            <div
              className={`${
                resim3 ? "w-32 h-32" : "bg-gray-300 p-5"
              } rounded-md`}
            >
              {resim3 ? (
                <img
                  src={URL.createObjectURL(resim3)}
                  alt="Selected"
                  className="flex h-32 w-32"
                />
              ) : (
                <label htmlFor="3" className="cursor-pointer">
                  <FcAddImage className="h-16 w-16" />
                </label>
              )}
              <input
                type="file"
                id="3"
                accept="image/*"
                className="hidden"
                onChange={resimThreeHandle}
              />
            </div>

            <div
              className={`${
                resim4 ? "w-32 h-32" : "bg-gray-300 p-5"
              } rounded-md`}
            >
              {resim4 ? (
                <img
                  src={URL.createObjectURL(resim4)}
                  alt="Selected"
                  className="flex h-32 w-32"
                />
              ) : (
                <label htmlFor="4" className="cursor-pointer">
                  <FcAddImage className="h-16 w-16" />
                </label>
              )}
              <input
                type="file"
                id="4"
                accept="image/*"
                className="hidden"
                onChange={resimForHandle}
              />
            </div>
          </div>
        </div>

        {uploadLoading ? (
          <div className="flex mx-auto w-full mt-10 items-center justify-center ">
            <div
              className="  top-0 left-0 w-10 h-10 border rounded-full border-blue-500 animate-spin"
              style={{
                borderTop: "2px solid transparent",
                borderBottom: "2px solid transparent",
              }}
            ></div>
          </div>
        ) : (
          <div className=" mt-10 mb-5 flex flex-row justify-center">
            <button
              onClick={async() => await taramaHizmetiEkle()}
              className="px-12 py-2  bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
            >
              Gönder
            </button>
          </div>
        )}
      </div>

      <button
        className="fixed text-3xl bottom-4 right-4 w-16 h-16 bg-blue-500 text-white rounded-full shadow-lg flex items-center justify-center"
        onClick={() => {
          // FAB'a tıklandığında yapılacak işlemi buraya ekleyebilirsiniz
          navigate("/");
        }}
      >
        <AiOutlineHome></AiOutlineHome>
      </button>
      <NotificationContainer />
    </div>
  );
}

export default TaramaModelleme;
